<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="flex-col w-11/12 mx-auto mt-4" v-if="!isLoading">
      <div class="flex w-full justify-end mb-8">
          <div class="p-4 w-12/12 flex justify-begin">
            <div class="inline-flex rounded-md shadow-sm" role="group">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="40"><path fill="none" d="M0 0h24v24H0z"/><path d="M6 19H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm0-2v-1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1h2V9H4v8h2zM8 4v3h8V4H8zm0 13v3h8v-3H8zm-3-7h3v2H5v-2z"/></svg>
              <button type="button" class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white" @click="createPdf(true)">
                Completo
              </button>
              <button type="button" class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white" @click="createPdf(false)">
                Simplificado
              </button>
            </div>
          </div>
      </div>
      <div class="border-2 rounded-lg border-green-1002 p-3 mb-8">
        <h1 class="text-2xl font-bold mb-5">Currículo SENAR</h1>
        <div
          v-for="certificate in certificates"
          class="py-4 px-4 lg:w-1/3"
          :key="certificate.id"
        >
          <div class="h-full flex items-start">
            <div class="flex-col w-full">
              <div class="flex-grow">
                <a class="inline-flex">
                  <span class="flex-grow flex flex-col">
                    <span class="title-font font-medium font-bold text-gray-900"
                      >{{ certificate.started_at.split('/').pop() }} -
                      {{ certificate.course.name }}</span
                    >
                  </span>
                </a>
                <p class="leading-relaxed mb-5">
                  {{ certificate.city.name }}, {{ certificate.workload }}h
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg border-green-1002 p-3 mb-8">
        <div class="flex justify-between items-center">
          <h1 class="text-2xl font-bold mb-5">Currículo Complementar</h1>
          <router-link
            class="text-2xl text-green-1002"
            :to="{ name: 'my-curriculum' }"
          >
            <i class="ri-pencil-fill"></i>
          </router-link>
        </div>
        <div class="flex-col w-full mb-4 border-b-2 border-green-1002">
          <h2 class="text-xl font-bold">Formações Acadêmicas</h2>
          <div
            v-for="academicBackground in academicBackgrounds"
            class="py-4 px-4 lg:w-1/3"
            :key="academicBackground.id"
          >
            <div class="h-full flex items-start">
              <div class="flex-col w-full">
                <div class="flex-grow">
                  <a class="inline-flex">
                    <span class="flex-grow flex flex-col">
                      <span
                        class="title-font font-medium font-bold text-gray-900"
                        >{{
                          academicBackground.complete_started_at
                            .split('/')
                            .pop()
                        }}
                        - {{ academicBackground.institution }}</span
                      >
                    </span>
                  </a>
                  <p class="leading-relaxed mb-5">
                    {{ academicBackground.formation }}
                  </p>
                  <small class="mb-5">{{
                    academicBackground.education_level.name
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col w-full mb-4 border-b-2 border-green-1002">
          <h2 class="text-xl font-bold">Experiências</h2>
          <div
            v-for="experience in experiences"
            class="py-4 px-4 lg:w-1/3"
            :key="experience.id"
          >
            <div class="h-full flex items-start">
              <div class="flex-col w-full">
                <div class="flex-grow">
                  <a class="inline-flex">
                    <span class="flex-grow flex flex-col">
                      <span
                        class="title-font font-medium font-bold text-gray-900"
                        >{{ experience.complete_started_at.split('/').pop() }} -
                        {{ experience.company }}</span
                      >
                    </span>
                  </a>
                  <p class="leading-relaxed mb-5">{{ experience.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col w-full mb-4 border-b-2 border-green-1002">
          <h2 class="text-xl font-bold">Cursos Complementares</h2>
          <div
            v-for="complementaryCourse in complementaryCourses"
            class="py-4 px-4 lg:w-1/3"
            :key="complementaryCourse.id"
          >
            <div class="h-full flex items-start">
              <div class="flex-col w-full">
                <div class="flex-grow">
                  <a class="inline-flex">
                    <span class="flex-grow flex flex-col">
                      <span
                        class="title-font font-medium font-bold text-gray-900"
                        >{{
                          complementaryCourse.complete_issued_at
                            .split('/')
                            .pop()
                        }}
                        - {{ complementaryCourse.issuer }}</span
                      >
                    </span>
                  </a>
                  <p class="leading-relaxed mb-5">
                    {{ complementaryCourse.name }},
                    {{ complementaryCourse.workload }} horas
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col w-full mb-4">
          <h2 class="text-xl font-bold">Áreas de Interesse</h2>
          <div>
            {{ interestAreas }}
          </div>
        </div>
      </div>
    </div>
    <modal-layout>
      <template v-slot:header>
        <div class="flex items-center flex-col p-3">
          <i class="ri-alert-line text-4xl"></i>
          <h1 class="text-base">Atenção</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="p-5">
          <p class="mb-2">
            {{ user.name }}, percebemos que seu
            <strong>Currículo Complementar</strong> ainda não foi preenchido, é
            importante que mantenha o mesmo atualizado para ampliar suas
            <strong>oportunidades</strong> aqui na plataforma.
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <button
          @click="closeModal"
          class="bg-green-1000 py-1 px-5 text-white text-center cursor-pointer font-bold border rounded-full"
        >
          OK
        </button>
      </template>
    </modal-layout>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';
import ModalLayout from '../../../components/Modal';
import { mapGetters } from 'vuex';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'CompleteCurriculumIndex',

  title() {
    return `${process.env.VUE_APP_NAME} | Meu Currículo`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation,
    ModalLayout
  },

  data() {
    return {
      certificates: [],
      academicBackgrounds: [],
      experiences: [],
      complementaryCourses: [],
      interestAreas: [],
      gender: {
        label: ''
      },
      ageYears: '',
      totalWorkload: '',
      isLoading: true
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    async fetchCertificates() {
      const response = await axios.get(
        `/api/participantes/${this.user.participant_id}/certificados`
      );

      this.certificates = response.data.data;
    },
    async fetchAcademicBackgrounds() {
      const response = await axios.get(
        `/api/formacoes-academicas/${this.user.participant_id}`
      );

      this.academicBackgrounds = response.data.data;
    },
    async fetchExperiences() {
      const response = await axios.get(
        `/api/experiencias/${this.user.participant_id}`
      );

      this.experiences = response.data.data;
    },
    async fetchComplementaryCourses() {
      const response = await axios.get(
        `/api/cursos-complementares/${this.user.participant_id}`
      );

      this.complementaryCourses = response.data.data;
    },

    async fetchInterestAreas() {
      const response = await axios.get(
        `/api/areas-de-interesse/${this.user.participant_id}`
      );

      this.interestAreas = response.data.data
        .map(interestArea => interestArea.label)
        .join(', ');
    },

    getCurriculum() {
      axios
        .get(`/api/participantes/${this.user.participant_id}`)
        .then(response => {
          this.name = this.capitalize(response.data.data.name);
          this.email = response.data.data.email;
          this.birth_date = response.data.data.birthDate;
          this.ageYears = response.data.data.age;
          this.totalWorkload = response.data.data.totalWorkload;
          this.gender = response.data.data.gender;
          this.telephone = response.data.data.telephone;
          this.cellphone = response.data.data.cellphone;
          this.educationLevel = response.data.data.educationLevel;

          this.academicBackgrounds = response.data.data.academicBackgrounds;
          this.complementaryCourses = response.data.data.complementaryCourses;
          this.experiences = response.data.data.experiences;
          this.interestsAreas = response.data.data.interestAreas;

          axios
            .get(`/api/participantes/${this.user.participant_id}/certificados`)
            .then(({ data }) => {
              this.certificates = data.data;

              document.title = this.name;
            });

          if (
            response.data.data.academicBackgrounds.length == 0 &&
            response.data.data.experiences.length == 0 &&
            response.data.data.complementaryCourses.length == 0 &&
            response.data.data.interestAreas.length == 0
          ) {
            this.$modal.show('modal');
          }

          this.isLoading = false;
        });
    },

    closeModal() {
      localStorage.setItem('ComplementaryCurriculum', true);

      this.$modal.hide('modal');
    },

    createPdf(type) {
      if (this.user.role.name === 'Instructor') {
        this.name = this.user.name;
        this.educationLevel.name = 'Ensino Médio Completo';
      }
      let certificatesTables = this.certificates.map(certificate => {
        let body = [
          [
            {
              rowSpan: 3,
              text: certificate.course.name,
              style: 'tableHeader',
              alignment: 'center'
            },
            {
              text: 'Carga Horária',
              style: 'tableHeader'
            },
            {
              text: certificate.workload,
              style: 'tableHeader',
              alignment: 'center'
            }
          ],
          [
            {},
            {
              text: 'Data Início',
              style: 'tableHeader'
            },
            {
              text: certificate.started_at,
              style: 'tableHeader',
              alignment: 'center'
            }
          ],
          [
            {},
            {
              text: 'Data Fim',
              style: 'tableHeader'
            },
            {
              text: certificate.ended_at,
              style: 'tableHeader',
              alignment: 'center'
            }
          ]
        ];

        if (type) {
          body.push(
            [
              {
                colSpan: 3,
                text: 'Objetivo:',
                style: 'tableHeader',
                border: [true, false, true, false]
              }
            ],
            [
              {
                colSpan: 3,
                text: certificate.course.objective,
                border: [true, false, true, true],
                style: 'small'
              }
            ],
            [
              {
                colSpan: 3,
                text: 'Conteúdo Programático:',
                style: 'tableHeader',
                border: [true, false, true, false]
              }
            ],
            [
              {
                colSpan: 3,
                text: certificate.programmatic_content,
                border: [true, false, true, true],
                style: 'small'
              }
            ]
          );
        }

        return {
          style: 'tableExample',
          color: '#444',
          table: {
            widths: [300, '*', '*'],
            body: body
          }
        };
      });
      let academicBackgroundsTable =
        this.academicBackgrounds.length > 0
          ? this.academicBackgrounds.map(academicBackground => {
              return [
                {
                  text: academicBackground.education_level.name,
                  style: 'small'
                },
                {
                  text: academicBackground.institution,
                  style: 'small'
                },
                {
                  text: academicBackground.formation,
                  style: 'small'
                },
                {
                  text: academicBackground.complete_started_at,
                  style: 'small'
                },
                {
                  text: academicBackground.complete_ended_at
                    ? academicBackground.complete_ended_at
                    : 'Não concluido',
                  style: 'small'
                }
              ];
            })
          : [
              [
                {
                  colSpan: 5,
                  text: 'Nenhuma formação acadêmica cadastrada.',
                  style: 'small'
                },
                '',
                '',
                '',
                ''
              ]
            ];
      let experiences =
        this.experiences.length > 0
          ? this.experiences.map(experience => {
              return [
                {
                  text: experience.title,
                  style: 'small'
                },
                {
                  text: experience.company,
                  style: 'small'
                },
                {
                  text: experience.contact_person,
                  style: 'small'
                },
                {
                  text: experience.contact_phone,
                  style: 'small'
                },
                {
                  text: experience.complete_started_at,
                  style: 'small'
                },
                {
                  text: experience.complete_ended_at,
                  style: 'small'
                }
              ];
            })
          : [
              [
                {
                  colSpan: 6,
                  text: 'Nenhuma experiência cadastrada.',
                  style: 'small'
                },
                '',
                '',
                '',
                '',
                ''
              ]
            ];
      let complementaryCourses =
        this.complementaryCourses.length > 0
          ? this.complementaryCourses.map(complementaryCourse => {
              return [
                {
                  text: complementaryCourse.name,
                  style: 'small'
                },
                {
                  text: complementaryCourse.issuer,
                  style: 'small'
                },
                {
                  text: complementaryCourse.workload,
                  style: 'small'
                },
                {
                  text: complementaryCourse.complete_issued_at,
                  style: 'small'
                }
              ];
            })
          : [
              [
                {
                  colSpan: 4,
                  text: 'Nenhum curso complementar cadastrado.',
                  style: 'small'
                },
                '',
                '',
                ''
              ]
            ];
      let interestAreass =
        this.interestsAreas.length > 0
          ? this.interestsAreas
              .map(interestsArea => interestsArea.label)
              .join(', ')
          : 'Nenhuma área de interesse cadastrada.';

      const curriculum = {
        pageSize: 'A4',
        pageMargins: [60, 130, 45, 120],
        background: [
          {
            image: 'background',
            width: 597
          }
        ],
        header: currentPage => {
          return {
            text: currentPage.toString(),
            absolutePosition: { x: 570, y: 75 },
            style: 'number'
          };
        },
        footer: () => {
          return {
            text: `Gerado por ${this.user.name}, com perfil de ${this.user.role.label}.`,
            absolutePosition: { x: 60, y: 100 },
            margin: [60],
            style: 'small'
          };
        },
        content: [
          {
            text: this.name,
            style: 'header'
          },
          {
            style: 'tableExample',
            layout: 'noBorders',
            color: '#444',
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Informações Pessoais',
                    style: 'tableHeader'
                  },
                  {
                    text: 'Contato',
                    style: 'tableHeader'
                  }
                ],
                [
                  {
                    text: `Sexo: ${this.gender.label}`
                  },
                  {
                    text: `E-mail: ${
                      !this.email.endsWith('@teste.com.br')
                        ? this.email
                        : 'Não informado'
                    }`
                  }
                ],
                [
                  {
                    text: `Idade: ${this.ageYears} anos`
                  },
                  {
                    text: `Celular: ${this.cellphone}`
                  }
                ],
                [
                  {
                    text: `Escolaridade: ${this.educationLevel.name}`
                  },
                  ''
                ]
              ]
            }
          },
          {
            text: ''
          },
          {
            text: `CERTIFICADOS SENAR-MT - Totalizando ${this.totalWorkload} horas`,
            style: 'subheader'
          },
          ...certificatesTables,
          {
            text: 'Currículo Complementar',
            style: 'subheader'
          },
          {
            text:
              '*Estas informações foram inseridas pelo talento, assim o SENAR-MT não se responsabiliza por sua veracidade.',
            style: 'small'
          },
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: ['*', '*', '*', '*', '*'],
              body: [
                [
                  {
                    colSpan: 5,
                    text: 'Formações Acadêmicas',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  '',
                  '',
                  '',
                  ''
                ],
                [
                  {
                    text: 'Escolaridade',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Instituição',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Formação',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Data Início',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Data Fim',
                    style: 'tableHeader',
                    alignment: 'center'
                  }
                ],
                ...academicBackgroundsTable
              ]
            }
          },
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: ['*', '*', '*', '*', '*', '*'],
              body: [
                [
                  {
                    colSpan: 6,
                    text: 'Experiências',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  '',
                  '',
                  '',
                  '',
                  ''
                ],
                [
                  {
                    text: 'Título',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Empresa',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Contato',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Telefone',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Data Início',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Data Fim',
                    style: 'tableHeader',
                    alignment: 'center'
                  }
                ],
                ...experiences
              ]
            }
          },
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  {
                    colSpan: 4,
                    text: 'Cursos Complementares',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  '',
                  '',
                  ''
                ],
                [
                  {
                    text: 'Nome',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Emissor',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Carga Horária',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Data Emissão',
                    style: 'tableHeader',
                    alignment: 'center'
                  }
                ],
                ...complementaryCourses
              ]
            }
          },
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: 'Áreas de Interesse',
                    style: 'tableHeader',
                    alignment: 'center'
                  }
                ],
                [
                  {
                    text: interestAreass,
                    style: 'small'
                  }
                ]
              ]
            }
          }
        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true
          },
          number: {
            fontSize: 20,
            bold: true,
            color: '#ffffff'
          },
          subheader: {
            fontSize: 15,
            bold: true
          },
          small: {
            fontSize: 10
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
          }
        },
        images: {
          background: `${process.env.VUE_APP_API_BASE_URL}/storage/papel_timbrado.jpg`
        }
      };

      pdfMake.createPdf(curriculum).open();
    },
    capitalize(name) {
      return name
        .replace(/\b\w/g, l => l.toUpperCase()) // Transforma todas as primeiras letras de cada palavra em Caixa Alta
        .replace(/\s(?:e|da|do|de|das|dos)+\s/gi, l => l.toLowerCase()); // Coloca todas as preposições em Caixa Baixa
    }
  },

  created() {
    if (this.user.role.name === 'Instructor') {
      this.user.participant_id = 235202;
    }
    this.fetchCertificates();
    this.fetchAcademicBackgrounds();
    this.fetchExperiences();
    this.fetchComplementaryCourses();
    this.fetchInterestAreas();
    this.getCurriculum();
  }
};
</script>

<style scoped></style>
